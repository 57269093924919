import { PropsWithChildren } from 'react';
import cx from 'classnames';
import { inputWrapperClasses, dropZoneMessageClasses } from '../styles';
import { DropZoneCSVChildProps } from '../types';
import { useDropZoneCSV } from '../useDropZoneCSV';
import { Button, BUTTON_VARIANTS } from '../../Button';
import { LoadingSpinner } from '../../Loading';

export function DropZoneCSVInput({
  children,
  onError,
  onUpload,
  toggleDropZone,
}: PropsWithChildren<DropZoneCSVChildProps>) {
  const { handleDrag, handleDrop, isUploading, message } = useDropZoneCSV({
    onError,
    onUpload,
  });
  return (
    <div className={'flex flex-col'}>
      <div
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        className={cx(inputWrapperClasses)}
      >
        {children}
        <Button
          className={'whitespace-nowrap ml-2 h-10'}
          disabled={isUploading}
          onClick={toggleDropZone}
          variant={BUTTON_VARIANTS.blue400}
        >
          {!isUploading ? <>{'Upload CSV'}</> : <LoadingSpinner />}
        </Button>
      </div>
      {message && <span className={cx(dropZoneMessageClasses)}>{message}</span>}
    </div>
  );
}

export default DropZoneCSVInput;
