import { Suspense } from 'react';
import { lazyLoad } from '@marketmuse/utilities';

import ScreenLoader from '../components/ScreenLoader';
import withTailwindCompatibility from '../hocs/withTailwindCompatibility';
import { NoMatch } from './_components/NoMatch';

const Organizations = withTailwindCompatibility({
  Component: lazyLoad(
    () =>
      import(
        /* webpackChunkName: 'Organizations' */ '../containers/Admin/Orgs/Organizations'
      ),
  ),
  style: { height: '100%' },
});

const OrganizationSingle = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'OrgCard' */ '../containers/Admin/Orgs/Organization'
    ),
);

export const routes = [
  {
    path: 'orgs',
    async lazy() {
      const mod = await import('../containers/Admin/Orgs/Organizations');
      return {
        Component: withTailwindCompatibility({
          Component: mod.default,
          style: {
            height: '100%',
          },
        }),
      };
    },
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <Organizations />
      </Suspense>
    ),
  },
  {
    path: 'orgs/:orgId',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <OrganizationSingle />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: <NoMatch />,
  },
];
