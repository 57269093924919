import { useCallback } from 'react';
import cx from 'classnames';
import { Button, BUTTON_VARIANTS } from '../../Button';
import { Icon, SUPPORTED_ICON } from '../../Icon';
import {
  labelClasses,
  inputClasses,
  dropAreaClasses,
  dropZoneIconClasses,
  ctaClasses,
  dropZoneMessageClasses,
} from '../styles';
import { DropZoneCSVChildProps } from '../types';
import { useDropZoneCSV } from '../useDropZoneCSV';
import { LoadingSpinner } from '../../Loading/LoadingSpinner';

export function DropZoneCSVBox({
  onError,
  onUpload: baseOnUpload,
  toggleDropZone,
}: DropZoneCSVChildProps) {
  const onUpload = useCallback(
    (files: string[][]) => {
      baseOnUpload && baseOnUpload(files);
      toggleDropZone();
    },
    [baseOnUpload, toggleDropZone],
  );

  const {
    isUploading,
    handleDrag,
    handleDrop,
    handleFileInputChange,
    message,
  } = useDropZoneCSV({ onError, onUpload });
  return (
    <label
      className={cx([
        ...labelClasses,
        isUploading ? ['cursor-not-allowed'] : ['cursor-pointer'],
      ])}
    >
      <input
        aria-label="Upload CSV"
        className={cx(inputClasses)}
        disabled={isUploading}
        type="file"
        onChange={handleFileInputChange}
      />

      <span
        className={cx(dropAreaClasses)}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        <span className={cx(dropZoneIconClasses)}>
          <Icon name={SUPPORTED_ICON.export} />
          <span>Drag & Drop a Single CSV</span>
        </span>
        {!isUploading ? (
          <span
            className={cx([
              ...ctaClasses,
              isUploading && ['opacity-70', 'cursor-not-allowed'],
            ])}
          >
            Browse Files
          </span>
        ) : (
          <LoadingSpinner />
        )}
        <Button
          aria-label="Cancel Dropzone Button"
          className={'h-7'}
          disabled={isUploading}
          onClick={toggleDropZone}
          variant={BUTTON_VARIANTS.gray600OnTransparent}
        >
          Cancel
        </Button>
        {message && (
          <span className={cx(dropZoneMessageClasses)}>{message}</span>
        )}
      </span>
    </label>
  );
}
