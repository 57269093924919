import { Suspense } from 'react';
import { lazyLoad } from '@marketmuse/utilities';

import ScreenLoader from '../components/ScreenLoader';

const TableViewRoutes = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'TableViewRoutes' */ '../containers/Inventory/TableView/TableViewLoader'
    ),
);

export const routes = [
  {
    path: ':routeDomain/:routeDomainValue?',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <TableViewRoutes />
      </Suspense>
    ),
  },
];
