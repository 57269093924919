interface InputPillProps {
  type?: string;
  placeholder?: string;
  className?: string | Array<string | string[]>;
  'aria-label'?: string;
  list?: string;
  id?: string;
}

export interface InputPillListProps {
  name: string;
  id?: string;
  items?: string[];
  enableDropZone?: boolean;
  onChange?: (items: string[]) => void;
  validation?: (value: string) => { valid: boolean; reason: string };
  inputProps?: InputPillProps;
  renderBefore?: React.ReactElement;
  renderAfter?: React.ReactElement;
  pillIconClassName?: string;
  inputWrapperClassName?: string | Array<string | string[]>;
}

export interface InputPillListState {
  inputValue: string;
  items: string[];
  isKeyReleased: boolean;
  inputStatus?: { valid: boolean; reason: string };
}

export enum InputPillListActions {
  ADD_NEW_ITEM = 'ADD_NEW_ITEM',
  REMOVE_ITEMS = 'REMOVE_ITEMS',
  ON_BACKSPACE = 'ON_BACKSPACE',
  ON_KEY_PRESS = 'ON_KEY_PRESS',
  SET_INPUT_VALUE = 'SET_INPUT_VALUE',
  SET_ITEMS = 'SET_ITEMS',
}
