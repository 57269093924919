import React, { useState } from 'react';

import { DropZoneCSVBaseParams } from './types';
import DropZoneCSVInput from './components/DropZoneCSVInput';
import { DropZoneCSVBox } from './components/DropZoneCSVBox';

interface DropZoneCSVProps extends DropZoneCSVBaseParams {
  enableDropZone: boolean | undefined;
}

const serializeViewMode = (
  enableDropZone: boolean,
  isDropBoxHidden: boolean,
) => {
  if (enableDropZone) {
    return isDropBoxHidden ? 'input' : 'csv-box';
  } else {
    return 'children';
  }
};

function DropZoneCSVWrapper({
  children,
  enableDropZone,
  onError,
  onUpload,
}: React.PropsWithChildren<DropZoneCSVProps>) {
  const [isDropBoxHidden, setIsDropBoxHidden] = useState(true);
  const mode = serializeViewMode(enableDropZone, isDropBoxHidden);

  return (
    <>
      {mode === 'input' && (
        <DropZoneCSVInput
          onError={onError}
          onUpload={onUpload}
          toggleDropZone={() => setIsDropBoxHidden(state => !state)}
        >
          {children}
        </DropZoneCSVInput>
      )}
      {mode === 'csv-box' && (
        <DropZoneCSVBox
          onError={onError}
          onUpload={onUpload}
          toggleDropZone={() => setIsDropBoxHidden(state => !state)}
        />
      )}
      {mode === 'children' && children}
    </>
  );
}

export default DropZoneCSVWrapper;
