import { Suspense } from 'react';
import { lazyLoad } from '@marketmuse/utilities';
import ScreenLoader from '../components/ScreenLoader';

const SignUpInitialize = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SignUpInitialize' */ '../containers/Account/SignUpInitialize'
    ),
);

const SignIn = lazyLoad(
  () => import(/* webpackChunkName: 'SignIn' */ '../containers/Account/SignIn'),
);

const SignInPost = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SignInPost' */ '../containers/Account/SignInPost'
    ),
);
const SignOut = lazyLoad(
  () =>
    import(/* webpackChunkName: 'SignOut' */ '../containers/Account/SignOut'),
);

const SignUp = lazyLoad(
  () => import(/* webpackChunkName: 'SignUp' */ '../containers/Account/SignUp'),
);

const MagicLinkInvitation = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'MagicLinkInvitation' */ '../containers/Account/MagicLinkInvitation'
    ),
);
const MagicLinkVerification = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'MagicLinkVerification' */ '../containers/Account/MagicLinkVerification'
    ),
);

const FallbackAuth = () => (
  <ScreenLoader.Auth>
    <div />
  </ScreenLoader.Auth>
);

export const routes = [
  {
    path: 'logout',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <SignOut />
      </Suspense>
    ),
  },
  {
    path: 'login',
    element: (
      <Suspense fallback={<FallbackAuth />}>
        <SignIn />
      </Suspense>
    ),
  },
  {
    path: 'invitation',
    element: (
      <Suspense fallback={<FallbackAuth />}>
        <MagicLinkInvitation />
      </Suspense>
    ),
  },
  {
    path: 'verification',
    element: (
      <Suspense fallback={<FallbackAuth />}>
        <MagicLinkVerification />
      </Suspense>
    ),
  },
  {
    path: 'post-login',
    element: (
      <Suspense fallback={<FallbackAuth />}>
        <SignInPost />
      </Suspense>
    ),
  },
  {
    path: 'signup',
    element: (
      <Suspense fallback={<FallbackAuth />}>
        <SignUp />
      </Suspense>
    ),
  },
  {
    path: 'welcome/:planName?',
    element: (
      <Suspense fallback={<FallbackAuth />}>
        <SignUpInitialize />
      </Suspense>
    ),
  },
  {
    path: 'welcome/signup/:planName?',
    element: (
      <Suspense fallback={<FallbackAuth />}>
        <SignUpInitialize />
      </Suspense>
    ),
  },
];
