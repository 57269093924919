import { pickBy } from 'lodash';
import { ACTION_TYPES } from '@marketmuse/data-state';

import * as types from '../config/types';
import initialState from '../config/initialState';

const persistReducer = (state = initialState.persist, action) => {
  // clear persisted values on signout
  if (action.type === ACTION_TYPES.SIGN_OUT) {
    const lastSelectedSites = pickBy(state, (_, key) =>
      /^userSite_/i.test(key),
    );
    return { ...initialState.persist, ...lastSelectedSites };
  }

  if (action.type === ACTION_TYPES.SET_SIMPLE && action.persist) {
    return { ...state, [action.key]: action.value };
  }

  if (action.type === types.SIDEBAR_TOGGLE) {
    return { ...state, sidebarCollapsed: !state.sidebarCollapsed };
  }

  return state;
};

export default persistReducer;
