export const wrapperClasses = [
  'relative',
  'w-full',
  'bg-white',
  'p-4',
  'border',
  'border-gray-300',
  'rounded-lg',
];

export const labelClasses = ['block', 'max-w-md', 'mx-auto', 'rounded-lg'];

export const inputClasses = ['hidden', 'absolute'];

export const dropAreaClasses = [
  'bg-white',
  'border-2',
  'border-dashed',
  'border-gray-300',
  'flex-col',
  'flex',
  'group',
  'hover:bg-gray-100',
  'items-center',
  'justify-center',
  'p-2',
  'rounded-lg',
  'space-y-4',
];

export const ctaClasses = [
  'text-sm',
  'font-bold',
  'leading-none',
  'cursor-pointer',
  'bg-blue-400',
  'text-white',
  'px-4',
  'py-2',
  'rounded-md',
  'hover:bg-blue-500',
];

export const inputWrapperClasses = [
  'rounded-md',
  'relative',
  'flex',
  'flex-row',
];

export const dropZoneIconClasses = [
  'flex',
  'flex-row',
  'gap-2',
  'max-h-4',
  'text-l',
  'font-medium',
  'text-gray-900',
  'items-center',
  'justify-center',
];
export const dropZoneMessageClasses = [
  'block',
  'text-sm',
  'font-bold',
  'mx-auto',
  'bg-transparent',
  'mt-4',
];
