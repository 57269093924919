import {
  ChangeEventHandler,
  DragEventHandler,
  useCallback,
  useState,
} from 'react';
import { DropZoneCSVBaseParams } from './types';

export const useDropZoneCSV = ({
  onError,
  onUpload,
}: DropZoneCSVBaseParams) => {
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState<null | string>(null);

  const parseFiles = useCallback(
    async files => {
      setMessage(null);
      const csvFiles = files.filter(
        file => file.type === 'text/csv' || file.name.endsWith('.csv'),
      );

      if (csvFiles.length === 0) {
        const message = 'Please use a valid CSV file.';
        setMessage(message);
        onError && onError(message);
        return null;
      }

      const file = csvFiles[0];

      if (csvFiles.length > 1) {
        const errorText =
          'Multiple files uploaded. Please upload one file only.';
        setMessage(errorText);
        onError && onError(errorText);
        return null;
      }

      const parseFile = async (): Promise<Array<string[]>> =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = event => {
            const fileContent = event.target.result;
            if (typeof fileContent !== 'string') {
              const errorText = 'File must be formatted as text in CSV format.';
              setMessage(errorText);
              return reject(errorText);
            }
            const parsedData = fileContent
              .split('\n')
              .map(row => row.split(','));
            return resolve(parsedData);
          };

          reader.onerror = error => {
            const errorText = `Error reading file: ${error.target.error}`;
            setMessage(errorText);
            return reject(errorText);
          };

          reader.readAsText(file);
        });

      try {
        const result = await parseFile();
        setMessage(null);
        return result;
      } catch (error) {
        setMessage(error);
        onError(error);
        return null;
      }
    },
    [onError],
  );

  const handleDrag = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop: DragEventHandler<HTMLDivElement> = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      const files = Array.from(event.dataTransfer.files);
      setIsUploading(true);
      parseFiles(files)
        .then(result => {
          result && onUpload(result);
        })
        .finally(() => setIsUploading(false));
    },
    [onUpload, parseFiles],
  );

  const handleFileInputChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      event => {
        event.preventDefault();

        setIsUploading(true);
        const files = Array.from(event?.target?.files || []);
        parseFiles(files)
          .then(result => {
            result && onUpload(result);
          })
          .finally(() => setIsUploading(false));
      },
      [parseFiles, onUpload],
    );

  return {
    handleDrag,
    handleDrop,
    handleFileInputChange,
    isUploading,
    message,
    setIsUploading,
    setMessage,
  };
};
